
import { Component, Vue } from 'vue-property-decorator';
import { FileDetail } from '@gsk-tech/gsk-file-upload/gsk-file-upload-base';
import { RawLocation } from 'vue-router';
import { RouteNames } from '@/constants';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GAnalytics from '@/components/GAnalytics';
import { ClickData } from '@/analytics';

interface FileEvent extends CustomEvent {
  detail: {
    value: FileDetail[];
  };
}

interface AssessmentStep {
  number: number;
  title: string;
  description: string;
}

@Component({
  components: {
    FullScreenForm,
    GAnalytics,
  },
})
export default class AssessmentLandingPage extends Vue {
  private modalClosed = false;

  private closeRoute: RawLocation = {
    name: RouteNames.ListingType,
    params: {
      type: 'rpa',
    },
  };

  closeAssessment() {
    this.$router.replace(this.closeRoute);
  }

  public assessmentSteps: AssessmentStep[] = [
    {
      number: 1,
      title: 'Add general details',
      description:
        'Name and describe the process you want to automate. Provide the business owner of the process, as well as enterprise and global process owners if you know them.',
    },
    {
      number: 2,
      title: 'Add automation details',
      description:
        'Answer a few questions to help determine what type of automation your business process may be a good fit for and how ready it is to automate.',
    },
    {
      number: 3,
      title: 'Add business details',
      description:
        'Add details including the systems, technologies, regulations, and data types your process relies on to help define the complexity and feasibility of automating.',
    },
    {
      number: 4,
      title: 'Add value details',
      description:
        'This information will help the Automation team determine how much value automating this process will create for the business and your team.',
    },
  ];

  public benefitItems: string[] = [
    'Risk reduction',
    'Reduce human error',
    'Improve employee’s experience and engagement',
    'Optimize resource allocation',
    'Speed up execution time',
  ];

  public automationIdeasLeft: string[] = ['Customer service', 'Invoice processing', 'Sales orders'];

  public automationIdeasRight: string[] = ['Payroll', 'HR & recruitment', 'Data management'];

  public platformsSupportedLeft: string[] = [
    'Microsoft Office',
    'Integrify',
    'JIRA',
    'Confluence',
    'SAP',
  ];

  public platformsSupportedRight: string[] = [
    'ServiceNow',
    'Concur',
    'MyLearning',
    'Harmony',
    'Adobe',
  ];

  get enterAssessment(): RawLocation {
    return {
      name: RouteNames.OpportunityAssessment,
    };
  }

  get beginAssessmentAnalytics(): ClickData {
    return {
      clickTarget: 'Begin Assessment',
    };
  }
}
